@import url(https://fonts.googleapis.com/css?family=Poppins:400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main: rgb(63, 81, 181);
  --toolbar: var(--secondary);
  --secondary: #12151F;
  --warning: rgb(255, 59, 45);
  --highlight: #D4F1F4;
  --background: #051622;
  --outline: #2a2f3f;
  --text: #fff;
  --highlight-text: #DEB992;
  --highlight-background: #172935;
  --card-color: #12151F;
  --reader-font: 'Poppins', sans-serif;
  --scroll-bar: #444;
  --scroll-track: #727272;
}
.white{
  color: #fff !important;
}

.filter-button{
  color: black;

}
.filter-button img{
  -webkit-filter: brightness(0);
          filter: brightness(0);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  color: var(--text)
}

html {font-size: 100%;} /*16px*/

body {
  background: #051622;
  background: var(--background);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #fff;
  color: var(--text)
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.3;
}


h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {font-size: 2.441rem;}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

.h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

.h2 {font-size: 2.441rem;}

.h3 {font-size: 1.953rem;}

.h4 {font-size: 1.563rem;}

.h5 {font-size: 1.25rem;}

.p  {font-size: 1rem}

small, .text-small {font-size: 0.8rem;}
.container{
  
  margin: 5rem 3rem;
}

button, .button {
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  font-size: 1rem;
  text-transform: capitalize;
  outline: 0;
  border: 0;
  cursor: pointer;
  color: #fff;
  background-color: rgb(63, 81, 181);
  background-color: var(--main);
  padding: .625rem;
  border: none;
  border-radius: 5px;

}

.MuiButton-root span{
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: ripple 600ms linear;
          animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0;
  }
}

@keyframes ripple {
  to {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0;
  }
}

*::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #727272;
  background-color: var(--scroll-track);
}

*::-webkit-scrollbar
{
  width: 10px;
  background-color: #727272;
  background-color: var(--scroll-track);
}

*::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #444;
  background-color: var(--scroll-bar);
}
.App{
  margin-top: 60px
}
.justify-left{
  margin: 0;
  margin-right: auto;
  
}
nav{
  background: #12151F;
  background: var(--toolbar);
  padding: 10px;
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid #2a2f3f;
  border-bottom: 1px solid var(--outline);
}
nav .logo{
  height: 30px;

}
nav form{
  display: -webkit-flex;
  display: flex;
  margin-left: 2rem;
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);
  
}

.search{
  -webkit-flex: 0 1 17rem;
          flex: 0 1 17rem;


}
nav input{
  width: 100%;
  background-color: rgb(232, 240, 254);
  padding: 0rem;
  color: #000;
  border: none;
  padding: .1rem;
}
input:focus{
  outline: none;
}
nav button{
  background-color: rgb(232, 240, 254);
  padding: .1rem;
border: none;
border-left: 1px solid #2a2f3f;
border-left: 1px solid var(--outline);
}
nav button svg{
  fill: #444 !important;
}
nav .link .MuiIconButton-root{
 height: 23px;
}

nav ul{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;

}
nav ul li{
  margin: 0 .625rem;
  list-style: none;

}
nav ul li a{
  color: #fff;
  
  text-decoration: none;
}
nav ul li a svg{
  fill: #fff !important;

}
.sign-in{
 text-align: center;
}
.select{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  
  width: 100%;
}
.books{
  height: 85vh;
  -webkit-flex: 0 1 37rem;
          flex: 0 1 37rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px; 
}
.book:hover{
  border: 1px #DEB992 solid;
  border: 1px var(--highlight-text) solid;
  color: #DEB992;
  color: var(--highlight-text);
}

.book{
  text-decoration: none;
  width: 100%;
  display: block;
  padding: .625rem;
  background: #12151F;
  background: var(--secondary);
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);
  color: #fff;
  color: var(--text);
}
.books .book-active{
  color: rgb(63, 81, 181);
  color: var(--main);
  border: 1px rgb(63, 81, 181) solid;
  border: 1px var(--main) solid;
}

.chapters{
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: .625rem; 
  -webkit-flex: 0 1 37rem; 
          flex: 0 1 37rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.chapter-container{
  -webkit-flex: 0 0 6.5rem;
          flex: 0 0 6.5rem;
  margin-right: .5rem;
  height: 6.5rem;
  margin-bottom: .5rem;
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);
  background: #12151F;
  background: var(--secondary);
  
}
.chapter-container:hover{
  border: 1px solid #DEB992;
  border: 1px solid var(--highlight-text);
  color: #DEB992;
  color: var(--highlight-text);
}
.chapter-container:hover .chapter{
  color: #DEB992;
  color: var(--highlight-text);
}
.chapter{
  background: #12151F;
  background: var(--secondary);
  text-decoration: none;
  width: 100%;
  display: block;
  padding: .625rem;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Biblenav{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 40px;
  }

.Biblereader{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.Bibletitle{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;

}
.reference{
  width: calc(90% - 9.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.Bibletitle button{
  -webkit-flex: 0 0 1.777rem;
          flex: 0 0 1.777rem;
  border: none;
  background: transparent;
}
.Bibletitle div{
  height: 2.25rem;
}
.Bibletitle button img{
width: 2.25rem;
height: 2.25rem;
}
.Biblemain{
  -webkit-flex: 0 2 100%;
          flex: 0 2 100%;
  margin-right: 25px;
  
}
.reader{
  max-height: 80vh;
  overflow-y: auto;
  padding: .625rem;
  font-family: 'Poppins', sans-serif;
  font-family: var(--reader-font);
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);

}
.reader .verse{
  cursor: pointer;
}
.reader .verse-active{
  color: #DEB992;
  color: var(--highlight-text);
}
.reader .verse-active sup{
  color: #DEB992;
  color: var(--highlight-text);
}
.default{
  font-family: 'Poppins', sans-serif;
}
.georgia{
  font-family:  Georgia, 'Times New Roman', Times, serif;
}
.verdana{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.monospace{
  font-family: monospace;
}
.sidebar{
  margin-left: 25px;
  overflow: hidden;
  -webkit-flex: 0 1 37rem;
          flex: 0 1 37rem;
  
  

}
.MuiDrawer-paper .sidebar{
  padding: .5rem;
  width: 400px;
  margin: 0;
  -webkit-flex: none;
          flex: none;
  height: 100vh;
  background: #051622;
  background: var(--background);
  border-left: 1px solid #2a2f3f;
  border-left: 1px solid var(--outline);
}
.MuiDrawer-paper .sidebarmain{
height: calc(100vh - 64px);
max-height: 100%;
border: none;
}



.sidebarmain{
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);
}
.main{
  margin: 4rem auto 0 auto;
  
  width: 40rem;
  }
  


.comment{

  width: 100%;
  
  
  padding: .5rem;
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: 40px 1fr 1fr 1fr 2rem 2rem;
  grid-template-rows: 15px auto 25px;
  grid-template-areas: 
    "photo user user user date date"
    "photo comment comment comment comment edit" 
    
    " load-more load-more load-more . verse verse";
}
.reply{
  
  
  grid-template-columns: 40px 1fr 1fr 1fr 2rem 2rem;
  grid-template-rows: 15px auto auto;
  grid-template-areas: 
    "photo user user user date date "
    "photo  comment comment comment comment edit" 
    
    "icons icons icons . . .";
}
.feed-comment{
  grid-template-columns: 40px 1fr 1fr 1fr 4rem;
  grid-template-rows: 15px auto auto  15px;
  grid-template-areas: 
    "photo user user date date"
    "photo  passage passage passage passage" 
    ". comment comment comment comment" 
    "verse verse verse verse .";
}
.bookmark{
  grid-template-columns: 40px 1fr 1fr 1fr 4rem;
  grid-template-rows: 15px auto 15px;
  grid-template-areas: 
    ". . . . date"
    "photo  verse verse verse edit"
    ". . . . .";
}
.comment-replies{
  margin: .5rem 0;
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);
  
  
  border-left: rgb(63, 81, 181) 5px solid;
  
  
  border-left: var(--main) 5px solid;
  background: #12151F;
  background: var(--card-color);
}

.comment-replies-home{
  margin: 1rem 1rem;
}
.replies{
  border-top: 1px solid #2a2f3f;
  border-top: 1px solid var(--outline);
  width: 100%;
}
.reply{
  border-bottom: 1px solid #2a2f3f;
  border-bottom: 1px solid var(--outline);
  padding-left: .5rem;
}

.comment-replies-home .reply{
  padding-left: calc(1rem + 40px);
}
.comment-replies-home .reply:last-child{
  border-bottom: none;
}
.reply-field{
  display: -webkit-flex;
  display: flex;
  border-top: 1px solid #2a2f3f;
  border-top: 1px solid var(--outline) ;
  margin: 0 !important;
  
  padding: .5rem;
  

  
}
.reply-field .form__label{
  margin-left: .5rem;
}
.reply-field .replyText{
  margin: 0;
  margin-left: .5rem;
  padding: .5rem;
  padding-left: 0;
  height: 4rem;
  color: #fff;
  color: var(--text);
  font-family: 'Poppins', sans-serif;
  
  font-size: 1rem;

}
.replyText{
  outline: none;
}

.comment .text-active{
  color: #DEB992;
  color: var(--highlight-text);
  
}
.comment .user{
  grid-area: user;
  margin-top: -5px;

}
.comment .load-more{
  grid-area: load-more;
  width: 144px;
  padding: 0;
}
.comment .edit{
  grid-area: edit;

}
.comment .photo{
  grid-area: photo;
}
.bookmark-photo{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

}
.comment .photo img {
width: 100%;
} 
.comment .comment-icons{
  grid-area: icons;
}
.comment .comment-icons button{
  padding: 0;
}

.comment .date{
  grid-area: date;
  text-align: right;
}
.comment .verse{
  grid-area: verse;
  text-align: right;

}
.bookmark .verse{
  text-align: left;
}
.comment .passage{
  grid-area: passage;
  
}
.comment .text{
  grid-area: comment;
  
}
.result{
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);
  border-left: rgb(63, 81, 181) 5px solid;
  border-left: var(--main) 5px solid;
  background: #12151F;
  background: var(--card-color);
  max-width: 50rem;
  width: 100%;
  min-height: 100px;
  margin: 1rem auto;
  
  padding: .5rem;
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: 3rem 1fr 2rem;
  grid-template-rows:  1fr;
  grid-template-areas: 
  'photo username .'

}
.result .userphoto{
  grid-area: photo;
  width: 3rem;
  align-self: flex-start;
}
.result .username{
  grid-area: username;
  align-self: flex-start;
}

.dashboard{
  position: fixed;
  top: 49px;
  padding: .5rem;
  left: 0;
  width: 15.75rem;
  height: 100vh;
  border: 1px solid #2a2f3f;
  border: 1px solid var(--outline);
  background: #12151F;
  background: var(--secondary);
}
.dashboard-drawer{
  display: block;
    position: static;
  top: 0;
 
}

.profilepicture{
  width: 50%;
  margin-left: .625rem;
}

.home-links::before, .home-links::after{
  content: '';
  width: 100%;
  margin: 0 auto;
  height: 1px;
  display: block;
  background-color: #2a2f3f;
  background-color: var(--outline);
}
.home-links li{
  list-style: none;
  display: block;
  width: 100%;
  
}
.home-links li a{
  padding: .625rem;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.home-links h4{
  margin: 0 0 0 .5rem;
}

.home-link{
  
  display: -webkit-flex;
  
  display: flex;

}
a{
  text-decoration: none;
}
.user-link{
border-radius: 5px;
}
.home-link:hover{
  background: #172935;
  background: var(--highlight-background);
}
.home-link img{
  margin-right: 1rem;
}
select {
	font-size: 1rem;
	color: #fff;
	color: var(--text);
	padding: .25rem;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #2a2f3f;
	border: 1px solid var(--outline);
	background-color: #12151F;
	background-color: var(--secondary);
}

select:hover {
	border-color: #888;
}
select:focus {
	border-color: #888;
	outline: none;
}
 option {
	font-weight:normal;
}
/* toggle button CSS */
.switch {
  position: relative;
  top: .125rem;
  left: .5rem;
  display: inline-block;
  width: 3.1rem;
  height: 1.438rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2a2f3f;
  background-color: var(--outline);
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.038rem;
  width: 1.038rem;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgb(63, 81, 181);
  background-color: var(--main);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(63, 81, 181);
  box-shadow: 0 0 1px var(--main);
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.5rem);
  transform: translateX(1.5rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* and toggle button CSS */
/*form CSS*/
.addComment{
margin-bottom: 40px ;
background: #12151F;
background: var(--secondary);
padding: 1rem;
border: 1px solid #2a2f3f;
border: 1px solid var(--outline);
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.highlight{
  color: #DEB992;
  color: var(--highlight-text);
}

.form__field::-webkit-input-placeholder {
  color: transparent;
}

.form__field:-ms-input-placeholder {
  color: transparent;
}

.form__field::placeholder {
  color: transparent;
}
.form__field:-ms-input-placeholder ~ .form__label {
  cursor: text;
  top: 20px;
}
.form__field:placeholder-shown ~ .form__label {
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  color: #9b9b9b;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 500;
  border-bottom: solid 3px rgb(63, 81, 181);
  border-bottom: solid 3px var(--main);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  color: rgb(63, 81, 181);
  color: var(--main);
  font-weight: 700;
}
.form__group textarea{
width: 100%;
height: 150px;
}


/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}
.submission{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;

}
.submit{
  width: 100%;
  background-color: rgb(63, 81, 181);
  background-color: var(--main);
  
  
  color: #fff;
  text-align: center;
  -webkit-flex: 0 0 7rem;
          flex: 0 0 7rem;
}
.fab{
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.clear-button{
  
  padding: .25rem;
  background: transparent;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.selected, .notselected{
  padding: 1rem;
}
.follow{
  width: 5rem;
  background: #12151F;
  background: var(--secondary);
  border: 1px #2a2f3f solid;
  border: 1px var(--outline) solid;
  margin-right: .625rem;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.follow img{
  width: 50%;
  margin: .25rem auto;
}
.VIP-section{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  padding: .625rem;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  
}
.follow-list-item{
  width: 4rem;
  height: 6.25rem;
  margin: .25rem .5rem .25rem 0;
}
.right{
position: absolute !important;
right: 1rem;
top: 0;
  
}
.relative{
  position: relative;
}
.edit-container{
  position: relative;
}
.absolute-right{
  position: absolute;
  top: -0.4765rem;
  right: 0px;
  
}
.edit{
  transition: .5s;
  -webkit-clip-path: circle(100%);
          clip-path: circle(100%);
}

.cliped{
  -webkit-clip-path: circle(0%);
          clip-path: circle(0%);
}
.warning{
  background: rgb(255, 59, 45);
  background: var(--warning);
}
.inline{
  display: inline;
  border-radius: 0;
}
.mtop{
  margin-top: 1.38rem;
}
.mbottom{
  margin-bottom: 1.38rem;
}
/* loading animation */
.center{
  height: calc(100vh - 50px);
  width: 100vw;
  display: grid;
  place-items: center;

}
.top-left{
  position: fixed !important;
  top: 8px;
  left: 0;
  z-index: 2;
}
.top-left svg{
  
  fill: white
}
.noborder{
  border: none;
}

/* about page */
.about-main .hero{
  margin-top: -.5rem;
  height: 25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgb(63, 81, 181);
  background: var(--main);
  color: #fff;
}
.about-main .hero h2, .about-main .hero p{
  color: #fff;
} 

.first-section .MuiContainer-root:last-child{
    margin-bottom: -9rem;
}
.first-section .MuiContainer-root:first-child{
  margin-bottom: 12rem;
  margin-top: 7rem;
}
.second-section .MuiContainer-root:first-child{
  margin-bottom: 11rem;
}
footer{
  background: rgb(63, 81, 181);
  background: var(--main);
  padding: .5rem 0;
  margin: auto 0 0 0;
}
footer ul{
  list-style: none;
}
footer .MuiContainer-root{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;


}


.backgroundimg{
width: 90%;

position: relative;
}
.first-section .img-left{
  left: 20px;
  top: -250px;
}
.first-section .img-right{
  left: -20px;
  top: 125px;
}
.second-section .img-left{
  left: 20px;
  top: -175px;
}
.second-section .img-right{
  left: -20px;
  top: 100px;
}



@media screen and (max-width: 1200px) {
  .main{
    margin: 4rem 0 0 300px;
    padding-left: 0px;
  }
}
@media screen and (max-width: 1050px) {
  .main{
    margin: 4rem 0 0 auto;
    padding-left: 0px;
  }
}
@media screen and (max-width: 959px) {
  .about-main h2{
    margin-top: 2rem;
    padding: 0 10px;
  }
  .about-main p{
    padding: 0 10px;
  }
  .backgroundimg{
    padding: 3rem;
    width: 90%;
    
    position: static;
    }
  .first-section .MuiContainer-root:last-child{
    margin-bottom: 0;
}
.first-section .MuiContainer-root:first-child{
  margin-bottom: 0;
  margin-top: 0;
}
.second-section .MuiContainer-root:first-child{
  margin-bottom: 0;
}
  .Biblemain{
    margin-right: 0;
  }
  .dashboard-other{

  }
  .main{
    margin: 4rem 0 0 0;
    padding-left: 0px;
    width: 100%;
  }
  .feed-comment{
    width:100%;
    
  }
  .dashboard-other{
    position: static;
    height: auto;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: calc(100% - 2rem);
    margin: -7px 10rem 1rem 1rem;
  }
  .dashboard-other .VIP-section{
    display: none
  }

  .dashboard-other div,  .dashboard-other button{
    padding: 0;
    -webkit-flex: 0 0 30%;
            flex: 0 0 30%;
  }
  .dashboard img{
    max-width: 7rem;
  }

}
